import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { siteMetaCtx } from "../store/siteMeta/context"

import { RoomFullPage } from "../components/RoomPage/RoomFullPage"
import { RoomBody } from "../components/RoomPage/RoomBody"
// import { IntroAbout } from "../components/homePage/IntroAbout"
// import { IntroContact } from "../components/homePage/IntroContact"
// import { IntroMoreInfo } from "../components/homePage/IntroMoreInfo"

const RoomTemplate = ({ pageContext, uri }) => {
  const { room_content,basic_info, full_page, display_images } = pageContext.acf

  return (
    <siteMetaCtx.Provider value={{ uri }}>
      <Layout>
        <SEO
          lang="zh-TW"
          title={basic_info.seo_title}
          description={basic_info.seo_description}
        ></SEO>
        <RoomFullPage data={full_page} />
        <section className="room-page position-relative">
          <RoomBody data={pageContext.acf} images={display_images} />
        </section>
      </Layout>
    </siteMetaCtx.Provider>
  )
}

export default RoomTemplate
