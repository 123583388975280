import React, { useState, useEffect, useRef } from "react"
import ReactHtmlParser from "react-html-parser"
import Img from "gatsby-image"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Link } from "gatsby"

import { getImgFluid } from "../../utils/data"
import { Breadcrumb } from "../../ui/Breadcumb"



export const RoomBody = ({ data, images, ...props }) => {
  const [state, setstate] = useState({ tabIndex: 0 })
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const { room_content, basic_info } = data

  const handleTabClick = index => {
    setstate(prev => ({ ...prev, tabIndex: index }))
  }
  useEffect(() => {
    const $ = window && window.jQuery
    if ($) {
      if ($(".owl-carousel")) {
        $(".owl-carousel").trigger("destroy.owl.carousel")
      }
      $(".owl-carousel").owlCarousel({
        center: true,
        items: isSmScreen ? 1 : 1.6,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        margin: isSmScreen ? 0 : 20,
        stagePadding: isSmScreen ? 0 : 20,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
      })
    }
  }, [isSmScreen])

  useEffect(() => {
    const $ = window && window.jQuery

    if ($) {
      prevRef.current = $(".owl-prev")
      nextRef.current = $(".owl-next")
      if (prevRef.current || nextRef.current) {
        if (prevRef.current[0]) {
          prevRef.current[0].innerHTML =
            '<i class="fas fa-chevron-left nav-btn prev"></i>'
        }
        if (nextRef.current[0]) {
          nextRef.current[0].innerHTML =
            '<i class="fas fa-chevron-right nav-btn next"></i>'
        }
      }
    }
  }, [isSmScreen])

  return (
    <div className="room-body">
      <Breadcrumb
        paths={basic_info.breadcumb.map(path => ({
          text: path.name,
          link_url: path.link_name,
        }))}
      />
      <div id="pageCarousel" className="carousel slide mx-auto">
        <div className="owl-carousel">
          {images.map((image, idx) => (
            <Img
              fluid={getImgFluid(image)}
              alt={image.alt_text}
              fadeIn={false}
              className="h-100 carousel-image"
              key={idx}
            />
          ))}
        </div>
      </div>

      <div className="row m-0 position-relative wrapper">
        <div className="col-24 col-md-auto">
          <div className="room-title">
            <p className="m-0">
              {room_content.room_title} {room_content.room_type}
            </p>
          </div>
        </div>
        <div className="col-24 row m-0 no-gutters justify-content-center justify-content-md-start">
          {/* <div className="col-auto room-price">
            <p>定價 {room_content.prices.normal_price} / 晚</p>
          </div> */}
          <div className="col-auto mr-3 room-price">
            <p>假日 {room_content.prices.holiday_price} / 晚</p>
          </div>
          <div className="col-auto room-price">
            <p>平日 {room_content.prices.weekday_price} / 晚</p>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-outline-light mx-auto mt-3 mx-md-0 mt-md-0 main-btn booking-btn"
          onClick={() => {
            window.location.href = room_content.booking_button.link
          }}
        >
          {room_content.booking_button.text}
        </button>
      </div>

      <div className="divider px-5"></div>

      <div className="room-content wrapper py-1">
        <div className="row">
          <div className="col-24 col-md-4 row mx-0 mx-md-auto justify-content-between align-content-start align-items-start">
            {room_content.infomation_tab.map((tab, idx) => (
              <div className="col-8 col-md-24" key={idx}>
                <div
                  className={`tab-item ${
                    idx === state.tabIndex ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(idx)}
                >
                  {tab.infomation_title}
                </div>
              </div>
            ))}
          </div>
          <div className="col mt-3 mt-md-0">
            {ReactHtmlParser(
              room_content.infomation_tab[state.tabIndex]?.infomation_content
            )}
          </div>
        </div>
      </div>

      <div className="divider px-5 mb-0"></div>
      <div className="row justify-content-center py-5">
        <div className="col-auto">
          <Link to={basic_info.breadcumb.slice(-2)[0].link_name}>
            <button type="button" className="btn btn-outline-light main-btn">
              回上層
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
